<template>
  <div class="banner">
    <div class="banner__container _container">
      <swiper
          class="banner__swiper"
          :effect="'fade'"
          :speed="400"
          :pagination="{ clickable: true }"
      >
        <swiper-slide v-for="(slide, idx) in staticSlider" :key="idx">
          <a :href="slide.url" class="banner__content">
            <div class="banner__info">
              <h1 class="banner__title">{{ slide.title }}</h1>
              <div class="banner__text" v-if="slide.content">{{ slide.content }}</div>
            </div>
            <div class="banner__image">
              <img class="banner__image-desctop" :src="slide.image" alt="" />
              <img class="banner__image-mobile" :src="slide.image" alt="" />
            </div>
          </a>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import SwiperCore, { Pagination, EffectFade } from "swiper";
import img1 from '@/assets/images/banner/slide1.png'
import img2 from '@/assets/images/banner/slide2.png'

SwiperCore.use([Pagination, EffectFade]);

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      isOpenModal: false,
    };
  },
  computed: {
    staticSlider() {
      return [
        {
          title: "",
          content: "",
          image: img1,
          image_mobile: img1,
          url: ""
        },
        {
          title: "",
          content: "",
          image: img2,
          image_mobile: img2,
          url: ""
        },
        // Add more slides as needed
      ];
    },
  },
};
</script>

<style lang="scss">
.banner {
  padding: 27px 0 120px 0;

  @media (max-width: $tablet + px) {
    padding: 23.5px 0 90px 0;
  }
  @media (max-width: $mobileSmall + px) {
    padding: 20px 0 50px 0;
  }

  &__swiper {
    height: 460px;

    .swiper-slide {
      position: relative;
      background: #43b0f1;
      border-radius: 10px;
      overflow: hidden;
    }

    .swiper-pagination {
      text-align: left;
      left: 50px !important;
      bottom: 20px !important;
      @media (max-width: $tablet + px) {
        left: 35px !important;
      }
      @media (max-width: $mobile + px) {
        bottom: 25px !important;
      }
      @media (max-width: $mobileSmall + px) {
        left: 15px !important;
        bottom: 10px !important;
      }
    }

    .swiper-pagination-bullet {
      width: 28px;
      height: 5px;
      background: #757575;
      border-radius: 3px;
      opacity: 1 !important;

      &-active {
        background: #ffffff;
      }
    }
    @media (max-width: $pc + px) {
      max-height: 380px;
      height: 33vw;
    }
    @media (max-width: $mobileSmall + px) {
      height: 122vw;
      max-height: none;
    }
  }

  &__content {
    overflow: hidden;
    height: 100%;
    @media (min-width: $mobile + px) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__info {
    box-sizing: content-box;
    flex: 0 1 622px;
    display: flex;
    z-index: 1;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    padding: 0 46px;
    @media (max-width: $tablet + px) {
      flex: 0 1 550px;
      padding: 54px 35px;
      height: auto;
    }
    @media (max-width: $mobile + px) {
      padding: 35px 35px 0;
    }
    @media (max-width: $mobileSmall + px) {
      padding: 20px 20px 0;
      margin: 0 0 33px 0;
    }
  }

  &__title {
    color: #ffffff;
    font-weight: 600;
    font-size: min(max(24px, calc(1.75rem + ((1vw - 3.2px) * 1.25))), 40px);
    margin: 0 0 5px;
    @media (max-width: $tablet + px) {
    }
    @media (max-width: $mobileSmall + px) {
    }
  }

  &__text {
    color: #ffffff;
    font-weight: 600;
    font-size: min(max(24px, calc(1.75rem + ((1vw - 3.2px) * 1.25))), 40px);
    margin: 0 0 12px;
    @media (max-width: $tablet + px) {
    }
    @media (max-width: $mobileSmall + px) {
    }
  }

  &__btn {
    padding: 0px 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    background: #ffffff !important;
    color: #d81921 !important;
    border: none !important;
    margin-bottom: -220px;
    @media (max-width: 1375px) {
      margin-bottom: -150px;
    }
    @media (max-width: $mobileSmall + px) {
      font-size: 14px !important;
      height: 40px !important;
      margin-bottom: -740px;
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &-desctop {
      display: block;
    }
    &-mobile {
      display: none;
    }
    @media screen and (max-width: $mobileSmall + px) {
      &-desctop {
        display: none;
      }
      &-mobile {
        display: block;
      }
    }    
  }
}

.request {
  @media (min-width: $mobile + px) {
    padding: 65px 100px 100px;
  }
  &__titles {
    max-width: 260px;
    text-align: center;
    margin: 0 auto 37px;
  }

  &__title {
    margin: 0 0 11px 0;
  }

  &__second-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }

  &__form {
    max-width: 360px;
    margin: 0 auto;
  }

  &__items {
    margin: 0 0 22px 0;
  }

  &__item {
    width: 100%;
    border: 1px solid #E0E0E0;
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #77757F;
    padding: 0px;
    .counry {
      position: relative;
      cursor: pointer;
      &-selected {        
        display: flex;
        align-items: center;
        gap: 5px;
        padding-left: 10px;
      }
      &-list {
        position: absolute;
        display: flex;
        flex-direction: column;
        padding-top: 5px;
        gap: 5px;
        background: white;
        left: 10px;
        top: 100%;
        &-item {
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }
      img {
        width: 15px;
        height: 10px;
      }
    }
    input {
      border: none;
    }
    &:not(:last-child) {
      margin: 0 0 17px 0;
    }
  }

  &__btn {
    width: 100%;
    margin: 0 0 28px 0;
  }

  &__accept {
    display: flex;
    align-items: flex-start;
    gap: 0 9px;
  }

  &__checkbox {
    min-height: 16px;
    min-width: 16px;
    width: 16px;
    height: 16px;

    &:after {
      background: url("../../assets/icons/shopping-cart/check.svg") center
        no-repeat;
    }
  }

  &__small-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }
}
.phonemask {
  padding-left: 5px !important;
}
</style>