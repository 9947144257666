<template>
  <div class="_container">
    <h2 class="main-title">Рассчитать стоимость автомобиля</h2>
    <div class="credit-form">
      <form @submit.prevent="submitForm">
        <input type="number" v-model="costOfCar" placeholder="Стоимость авто" required>
        <input type="number" v-model="initialInstalment" placeholder="Первоначальный взнос" required>
        <select v-model="instalmentPeriod" required>
          <option value="" disabled>Выберите срок рассрочки</option>
          <option value="12">12 Месяцев</option>
          <option value="24">24 Месяцев</option>
          <option value="36">36 Месяцев</option>
          <option value="48">48 Месяцев</option>
        </select>
        <input type="text" v-model="name" placeholder="Имя" required>
        <input type="tel" v-model="phoneNumber" placeholder="Номер телефона" required>
        <input type="number" v-model="iin" placeholder="ИИН" required>

        <button type="submit">Отправить</button>
      </form>

      <div v-if="showSuccess" class="success-message">Успешно!</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: '',
      phoneNumber: '',
      iin: '',
      costOfCar: '',
      initialInstalment: '',
      instalmentPeriod: '',
      showSuccess: false
    };
  },
  methods: {
    submitForm() {
      this.showSuccess = true;
    }
  }
};
</script>

<style scoped lang="scss">
.main-title {
  text-align: center;
  margin-bottom: 30px;
}

.credit-form {
  max-width: 300px;
  margin: 0 auto;
  padding-bottom: 100px;

  form {
    display: flex;
    flex-direction: column;

    input, select {
      margin-bottom: 10px;
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }

    button {
      padding: 10px;
      background-color: #057dcd;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }
  }

  .success-message {
    margin-top: 10px;
    padding: 10px;
    background-color: #28a745;
    color: #fff;
    border-radius: 4px;
  }
}
</style>
