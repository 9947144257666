<template>
  <div class="categories">
    <div class="categories__container _container">
      <div class="categories__items">
        <div
            class="categories__item"
            v-for="(category, i) in categories"
            :key="i"
            :class="`categories__item-${i}`"
        >
          <div
              @click="selectCategory(category)"
              class="categories__wrapper"
          >
            <div class="categories__name">{{ category.title }}</div>
            <div class="categories__image" :class="`categories__image-${i}`">
              <img :src="category.image" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import img1 from '@/assets/images/car/monza.png'
import img2 from '@/assets/images/car/changan.png'
import img3 from '@/assets/images/car/jac.png'
import img4 from '@/assets/images/car/hyundai.png'

export default {
  data() {
    return {
      categories: [
        {
          id: 1,
          title: 'Седан',
          image: img1,
          slug: 'sedan'
        },
        {
          id: 2,
          title: 'Хэтчбек',
          image: img2,
          slug: 'hetchbek'
        },
        {
          id: 3,
          title: 'Кроссовер',
          image: img3,
          slug: 'crossover'
        },
        {
          id: 4,
          title: 'Внедорожник',
          image: img4,
          slug: 'suv'
        },
        {
          id: 5,
          title: 'Гибрид',
          image: img3,
          slug: 'hybrid'
        },
      ]
    };
  },
};
</script>

<style lang="scss">
.categories {
  padding: 0px 0 120px 0;
  @media (max-width: $tablet + px) {
    padding: 0px 0 90px 0;
  }
  @media (max-width: $mobileSmall + px) {
    padding: 0px 0 60px 0;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
    row-gap: 30px;
    @media (max-width: $tablet + px) {
      grid-template-columns: calc(50% - 5px) calc(50% - 5px);
      grid-template-rows: repeat(3, 180px);
      row-gap: 25px;
    }
    @media (max-width: $mobile + px) {
      grid-gap: 10px;
    }
    @media (max-width: $mobileSmall + px) {
      grid-template-rows: 145px 145px 145px;
      row-gap: 20px;
    }
  }

  &__item {
    cursor: pointer;
    position: relative;
    background: #ececec;
    width: 32%;
    height: 196px;
    border-radius: 10px;
    overflow: hidden;
    padding: 0 0 35px 25px;
    @media (max-width: $tablet + px) {
      height: 180px;
    }
    @media (max-width: $mobileSmall + px) {
      padding: 0 8px 23px;
      width: 48%;
      height: 145px;
    }

    &-1 {
      .categories__name {
        max-width: 292px;
      }
    }

    &-3 {
      grid-column: 3;
      grid-row: 1 / 3;
      padding: 59px 0 0 25px;
      @media (max-width: $tablet + px) {
        grid-column: 1 / 3;
        grid-row: 3;
      }

      .categories__wrapper {
        align-items: flex-start;
        position: relative;
      }
    }
  }

  &__wrapper {
    height: 100%;
    display: flex;
    align-items: flex-end;
  }

  &__name {
    font-weight: 600;
    font-size: 24px;
    line-height: 120.84%;
    max-width: 225px;
    @media (max-width: $tablet + px) {
      font-size: 20px;
    }
    @media (max-width: $mobileSmall + px) {
      font-size: 15px;
    }
  }

  .categories__name {
    margin-top: 70px;
    @media (max-width: 475px)  {
      margin-top: 45px;
    }
  }

  &__image {
    width: auto;
    height: 130px;
    position: absolute;
    top: 0;
    right: 0;

    @media (max-width: 475px)  {
      width: 60%;
      height: 90%;
    }

    &-3 {
      top: -50px;
      @media (max-width: 475px)  {

      }
    }
  }
}
</style>