<template>
  <div class="brands">
    <div class="brands__container _container">
      <div class="brands__top">
        <h2 class="brands__title main-title">{{ $t('brands') }}</h2>
        <div class="brands__navigation">
          <div class="brands__right navigation-next">
            <span><img src="../../assets/icons/swiper/arrow.svg" alt=""/></span>
          </div>
          <div class="brands__left navigation-prev">
            <span><img src="../../assets/icons/swiper/arrow.svg" alt=""/></span>
          </div>
        </div>
      </div>
      <div class="brands__body">
        <swiper class="brands__swiper" :navigation="navigation" :space-between="34" :speed="1000" :slides-per-view="'auto'" :slides-per-group="4">
          <swiper-slide v-for="(brand, index) in staticBrands" :key="index">
            <img class="brands__img" :src="brand.image" alt="">
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from "swiper/vue/swiper-vue.js";
import SwiperCore, {Navigation} from "swiper";
import img1 from '@/assets/images/brands/brand1.png'

SwiperCore.use([Navigation]);
export default {
  components: {
    Swiper, SwiperSlide
  },
  data() {
    return {
      navigation: {
        nextEl: ".brands__left",
        prevEl: ".brands__right",
      },
      breakpoints: {
        767.98: {
          spaceBetween: 34,
        },
        0: {
          spaceBetween: 24,
        }
      }
    }
  },
  computed: {
    staticBrands() {
      return [
        {
          id: 1,
          image: img1
        },
        {
          id: 2,
          image: img1
        },
        {
          id: 1,
          image: img1
        },
        {
          id: 2,
          image: img1
        },
        {
          id: 1,
          image: img1
        },
        {
          id: 2,
          image: img1
        },
      ];
    }
  }
}
</script>

<style lang="scss">
.brands{
  padding: 0 0 120px 0;
  @media (max-width: $tablet + px) {
    padding: 0 0 90px 0;
  }
  @media (max-width: $mobileSmall + px) {
    padding: 0 0 60px 0;
  }
  &__container{
    @media (max-width: $tablet + px) {
      padding: 0 0 0 16px !important;
    }
  }
  &__top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 46px 0;
    gap: 0 20px;
    @media (max-width: $tablet + px) {
      margin: 0 0 40.5px 0;
      padding: 0 16px 0 0;
    }
    @media (max-width: $mobileSmall + px) {
      align-items: flex-end;
      margin: 0 0 35px 0;
    }
  }
  &__swiper{
    @media (max-width: $tablet + px) {
      padding: 0 16px 0 0!important;
    }
    .swiper-slide{
      width: auto;
    }
  }
  &__img {
    height: 132px;
    img {
      height: 100%;
      max-height: 100%;
      max-width: 100%;
    }
    @media (max-width: $mobileSmall + px) {
      height: 89px;
    }
  }
  &__navigation {
    display: flex;
    align-items: center;
    gap: 0 8px;
  }
}
</style>